import axios from 'axios'
import qs from 'qs'

import tool from '../utils/tool'

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.timeout = 5000
// axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.interceptors.response.use(response => {
    let r = response.data
    // todo 判断返回码 400 需要重新登录
    if (r.code === 400) {
        // 跳转登录
    }
    return r
}, error => {
    return Promise.reject(error)
})

export default (url, data = null, method = 'POST', contentType = 'application/x-www-form-urlencoded', timeout = 60000) => {
    if (method !== 'GET' && contentType === 'application/x-www-form-urlencoded') {
        data = qs.stringify(data)
    }
    const token = tool.getLocalStorage('token')
    // axios.defaults.headers.post['token'] = tool.isEmpty(token) ? '' : token
    return axios.request({
        url: url,
        method: method,
        data: data,
        timeout: timeout,
        headers: {
            token: tool.isEmpty(token) ? '' : token,
            'Content-Type': contentType,
            language: localStorage.getItem('locale')
        }
    })
}
